import * as React from "react"
import { ImgDestra, ImgSinistra, ImgCentro, ImgLogo, Img, Citazione, IndiceLuoghi, PaginaTesto, ChiSono, ChiSiamo, A, FbPage } from "../../../components/componentiPagine"

export default function Render() {
	return <PaginaTesto lang="it" name="amici_di_gallenga">
<h1>Grazie</h1>
<p>Questo progetto è stato realizzato grazie al forte partenariato tra varie associazioni e figure chiave, che hanno contribuito e continueranno a contribuire allo sviluppo di questa ricerca!</p>
<p>In primis il nostro GRAZIE va al <A href="https://it.wikipedia.org/wiki/Corrado_Truffelli">“Professor Corrado Truffelli”</A> e alla <A href="https://personale.unipr.it/it/ugovdocenti/person/21523">“Professoressa Gioia Angeletti”</A>, senza i quali Andrea Ghirarduzzi non avrebbe potuto fare la sua tesi triennale sul Gallenga e non ne avrebbe mai parlato con Elisa Bortolin che a sua volta non avrebbe mai contattato il Comune di Bedonia per chiedere a Fabio Ziliani di fare il sito e a Caterina de Nisco di fare le illustrazioni....</p>
<h2>I nostri partner</h2>
<ImgDestra href="http://www.cardinalecasaroli.org/associazioni/index.asp" nome="casaroli.jpg" alt="Ass. Casaroli"/>
<ImgSinistra href="http://www.valcenostoria.it/2021/04/27/associazioni-centro-studi-val-ceno/" nome="valceno.jpg" alt="Valceno"/>
<ImgDestra href="http://www.terramiacanavese.it/index.html" nome="terramia.png" alt="Terra Mia"/>
<ImgSinistra href="https://air.unipr.it/handle/11381/2860066?mode=simple.984#.YXASyBpBzIU" nome="unipr.png" alt="Università"/>
	</PaginaTesto>
}